<section class="medical_network">
  <div class="titles">
    <h2>{{title}}</h2>
    <h3>{{subtitle}}</h3>
  </div>
  <div class="people">
    <article>
      <img [src]="usersImage">
      <span class="number">{{usersNumber}}</span>
      <span class="text">{{usersText}}</span>
    </article>
    <article>
      <img [src]="doctorsImage" alt="">
      <span class="number">{{doctorsNumber}}</span>
      <span class="text">{{doctorsText}}</span>
    </article>
    <article>
      <img [src]="clinicsImage" alt="">
      <span class="number">{{clinicsNumber}}</span>
      <span class="text">{{clinicsText}}</span>
    </article>
  </div>
</section>
