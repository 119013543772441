import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { DoctorsComponent } from "./doctors.component";
import { SharedModule } from "../shared/shared.module";
import { EvolvesComponent } from './components/evolves/evolves.component';
import { IncreaseComponent } from './components/increase/increase.component';
import { PopUpDoctorsComponent } from './components/pop-up-doctors/pop-up-doctors.component';
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [DoctorsComponent, EvolvesComponent, IncreaseComponent, PopUpDoctorsComponent],
  imports: [BrowserModule.withServerTransition({ appId: 'serverApp' }), SharedModule, ReactiveFormsModule],
  providers: [],
  exports: [DoctorsComponent],
  bootstrap: [DoctorsComponent]
})
export class DoctorsModule { }
