import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { ClinicsComponent } from "./clinics.component";
import { SharedModule } from "../shared/shared.module";
import { TrustUsComponent } from './components/trust-us/trust-us.component';
import { BenefitsComponent } from './components/benefits/benefits.component';
import { PopUpClinicsComponent } from './components/pop-up-clinics/pop-up-clinics.component';
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [ClinicsComponent, TrustUsComponent, BenefitsComponent, PopUpClinicsComponent],
  imports: [BrowserModule.withServerTransition({ appId: 'serverApp' }), SharedModule, ReactiveFormsModule],
  providers: [],
  exports: [ClinicsComponent],
  bootstrap: [ClinicsComponent]
})
export class ClinicsModule { }
