<app-header (navigate)="navigateToDoctors()" [content]="content['Header Clinics']"></app-header>
<app-hero (toOpen)="openPop()" (navigate)="navigateToDoctors()" [content]="content['Hero Clinics']"></app-hero>
<app-trust-us [content]="content['Trust Us']"></app-trust-us>
<app-video [content]="content['Video Clinics']" (toOpen)="openPop()"></app-video>
<app-benefits [content]="content['Benefits']"></app-benefits>
<app-join-us [content]="content['Join Us Clinics']" (toOpen)="openPop()"></app-join-us>
<app-for-doctors [content]="content['Navigation Clinics']"></app-for-doctors>
<app-footer (toOpenContact)="openPopContact()" [content]="content['Footer Clinics']"></app-footer>
<app-pop-up-clinics *ngIf="popIsOpen" (toClose)="closePop()"></app-pop-up-clinics>
<app-pop-up-contact *ngIf="popContactIsOpen" (toCloseContact)="closePopContact()"></app-pop-up-contact>
