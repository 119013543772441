import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { ContentfulService } from '../services/contentful.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss'],
})
export class PatientsComponent implements OnInit {
  title = 'medpago_patients';

  content: { [key: string]: any } = {};

  popIsOpen: boolean;
  popContactIsOpen: boolean;
  popMailchimpIsOpen: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platform: Object,
    private renderer: Renderer2,
    private contentfulService: ContentfulService
  ) {
    this.popIsOpen = false;
    this.popContactIsOpen = false;
    this.popMailchimpIsOpen = false;
  }
  ngOnInit(): void {
    let data = this.contentfulService.patients;

    if (data) {
      data.forEach((section) => (this.content[section.name] = section));
    }
  }

  openPop() {
    this.renderer.addClass(document.body, 'overflow_hidden');
    this.popIsOpen = true;
    if (isPlatformBrowser(this.platform)) {
      setTimeout(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }, 200);
    }
  }

  openPopContact() {
    this.renderer.addClass(document.body, 'overflow_hidden');
    this.popContactIsOpen = true;
    if (isPlatformBrowser(this.platform)) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  openPopMailchimp() {
    this.renderer.addClass(document.body, 'overflow_hidden');
    this.popMailchimpIsOpen = true;
  }

  closePop() {
    this.popIsOpen = false;
    this.renderer.removeClass(document.body, 'overflow_hidden');
    if (isPlatformBrowser(this.platform)) {
    }
  }

  closePopContact() {
    this.popContactIsOpen = false;
    this.renderer.removeClass(document.body, 'overflow_hidden');
    if (isPlatformBrowser(this.platform)) {
    }
  }

  navigateToDoctors() {
    if (isPlatformBrowser(this.platform)) {
      const forDoctors = document.getElementById('forDoctors');
      if (forDoctors) {
        forDoctors.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
}
