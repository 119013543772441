import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Observable, Subscription, fromEvent } from 'rxjs';
//noinspection TypeScriptCheckImport,TypeScriptCheckImport
import Player from '@vimeo/player';
import { ActivatedRoute } from '@angular/router';

// const endedEvent$ = fromEvent(this.player1, 'ended');
// endedEvent$.subscribe(() => {
//   this.isPlay1 = false;
// });

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('vimeoVideo1') videoplayer1!: ElementRef;
  @ViewChild('vimeoVideo2') videoplayer2!: ElementRef;

  player1: any;
  player2: any;

  @Output() toOpen = new EventEmitter();

  @Input() content: {
    title: string;
    button: string;
  } = {
    title: '',
    button: '',
  };

  title = '';
  button = '';

  isPlay1: boolean = false;
  isPlay2: boolean = false;
  isMd: boolean = false;
  resizeObs$!: Observable<Event>;
  resizeSubs$!: Subscription;

  constructor(
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.title = this.content.title;
    this.button = this.content.button;

    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth <= 414) {
        this.isMd = false;
      } else {
        this.isMd = true;
      }
      this.resizeObs$ = fromEvent(window, 'resize');
      this.resizeSubs$ = this.resizeObs$.subscribe((e) => {
        if (window.innerWidth <= 414) {
          this.isMd = false;
        } else {
          this.isMd = true;
        }
      });
    }
  }

  ngAfterViewChecked(): void {
    if (!this.player1 && this.isPlay1) {
      this.player1 = new Player(this.videoplayer1.nativeElement);
      this.player1.play();
    }

    if (!this.player2 && this.isPlay2) {
      this.player2 = new Player(this.videoplayer2.nativeElement);
      this.player2.play();
    }
  }

  ngOnDestroy(): void {
    if (this.resizeSubs$) {
      this.resizeSubs$.unsubscribe();
    }
  }

  openPop() {
    this.toOpen.emit();
  }

  playVimeo1() {
    this.isPlay1 = !this.isPlay1;
  }

  playVimeo2() {
    this.isPlay2 = !this.isPlay2;
  }

  checkWindowSize() {
    if (window.innerWidth < 414) {
      this.isMd = false;
    } else {
      this.isMd = true;
    }
  }
}
