<section class="benefits">
  <article class="top">
    <h2 class="title">{{titleTop}}</h2>
    <div class="box">
      <div class="background">
        <div class="glass_layer"></div>
        <div class="sphere1_top_layer"></div>
        <div class="sphere2_top_layer"></div>
      </div>
      <div class="info">
        <div class="image">
          <img [src]="image1" alt="">
        </div>
        <div class="text">
          <h3>{{subtitle1}}</h3>
          <p>{{text1}}</p>
        </div>
      </div>
      <div class="info">
        <div class="image">
          <img [src]="image2" alt="">
        </div>
        <div class="text">
          <h3>{{subtitle2}}</h3>
          <p>{{text2}}</p>
        </div>
      </div>
      <div class="info">
        <div class="image">
          <img [src]="image3" alt="">
        </div>
        <div class="text">
          <h3>{{subtitle3}}</h3>
          <p>{{text3}}</p>
        </div>
      </div>
    </div>
    <div class="sphere1"></div>
    <div class="sphere2"></div>
  </article>
  <article class="bottom">
    <h2>{{titleBottom}}</h2>
    <div class="boxes_bottom">
      <div class="box_bottom">
        <span>{{percentage1}}</span>
        <p>{{textBottom1}}</p>
      </div>
      <div class="box_bottom">
        <span>{{percentage2}}</span>
        <p>{{textBottom2}}</p>
      </div>
    </div>
    <div class="sphere3"></div>
    <div class="sphere4"></div>
  </article>
</section>
