<section class="trust_us">
  <h2 class="title">{{title}}</h2>
  <div class="boxes">
    <div class="box">
      <img [src]="image1" alt="">
    </div>
    <div class="box">
      <img [src]="image2" alt="">
    </div>
  </div>
</section>
