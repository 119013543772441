import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

declare global {
  interface Window {
    Trustpilot: any;
  }
}

@Component({
  selector: 'app-trustpilot-box',
  templateUrl: './trustpilot-box.component.html',
  styleUrls: ['./trustpilot-box.component.scss'],
})
export class TrustpilotBoxComponent implements OnInit {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const trustboxRef = document.getElementById('trustbox');
      window.Trustpilot.loadFromElement(trustboxRef);
      console.log(window.Trustpilot);
    }
  }
}
