import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-your-needs',
  templateUrl: './your-needs.component.html',
  styleUrls: ['./your-needs.component.scss']
})
export class YourNeedsComponent implements OnInit{

  @Input() content: {
    title: string,
    image: {fields: {file: {url: string}}},
    infoTitle1: string,
    infoIcon1: {fields: {file: {url: string}}},
    infoText1: string,
    infoTitle2: string,
    infoIcon2: {fields: {file: {url: string}}},
    infoText2: string,
    infoTitle3: string,
    infoIcon3: {fields: {file: {url: string}}},
    infoText3: string,
  } = {
    title: '',
    image: {fields: {file: {url: ''}}},
    infoTitle1: '',
    infoIcon1: {fields: {file: {url: ''}}},
    infoText1: '',
    infoTitle2: '',
    infoIcon2: {fields: {file: {url: ''}}},
    infoText2: '',
    infoTitle3: '',
    infoIcon3: {fields: {file: {url: ''}}},
    infoText3: '',
  }

  title= '';
  image= '';
  infoTitle1= '';
  infoIcon1= '';
  infoText1= '';
  infoTitle2= '';
  infoIcon2= '';
  infoText2= '';
  infoTitle3= '';
  infoIcon3= '';
  infoText3= '';

ngOnInit(): void {
  this.title= this.content.title;
  this.image= this.content.image.fields.file.url;
  this.infoTitle1= this.content.infoTitle1;
  this.infoIcon1= this.content.infoIcon1.fields.file.url;
  this.infoText1= this.content.infoText1;
  this.infoTitle2= this.content.infoTitle2;
  this.infoIcon2= this.content.infoIcon2.fields.file.url;
  this.infoText2= this.content.infoText2;
  this.infoTitle3= this.content.infoTitle3;
  this.infoIcon3= this.content.infoIcon3.fields.file.url;
  this.infoText3= this.content.infoText3;

}
}
