<section class="your_needs">
  <h2 class="title_mobile">{{title}}</h2>
  <div class="container">
    <h2>{{title}}</h2>
    <div class="flexbox">
      <div class="img">
        <img [src]="image" alt="">
      </div>
      <div class="list">
        <div>
          <div class="icon">
            <img [src]="infoIcon1" alt="">
          </div>
          <div class="info">
            <h3>{{infoTitle1}}</h3>
            <p>{{infoText1}}</p>
          </div>
        </div>
        <div>
          <div class="icon">
            <img [src]="infoIcon2" alt="">
          </div>
          <div class="info">
            <h3>{{infoTitle2}}</h3>
            <p>{{infoText2}}</p>
          </div>
        </div>
        <div>
          <div class="icon">
            <img [src]="infoIcon3" alt="">
          </div>
          <div class="info">
            <h3>{{infoTitle3}}</h3>
            <p>{{infoText3}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
