<footer class="footer">
      <div class="behind">
        <h2>{{title}}</h2>
        <h3>{{subtitle}}</h3>
      </div>
      <div class="in_front">
        <div class="detail">
          <img src="../../../../assets/images/footer_detail.svg" alt="">
        </div>
     <div class="flexbox">
      <div class="subscribe">
        <div class="logo">
          <img src="../../../assets/icons/logo_white.svg" alt="">
        </div>
        <span>Suscríbete para recibir nuestras noticias</span>
        <div class="input">
          <input type="text" placeholder="email" [formControl]="input">
          <a [href]="'https://gmail.us21.list-manage.com/subscribe?u=c402e33aaaeb2fee3e5c7ee0f&id=d59433579c&MERGE0=' + input.value" target="_blank">¡Vamos!</a>
         <!-- <button (click)="addEmail(input.value)">¡Vamos!</button> -->
        </div>
      </div>
      <div class="anchors">
        <div class="anchors_left">
          <a href="">Sobre nosotros</a>
          <a [routerLink]="['/medicos']">Medpago para prestadores de servicios</a>
          <a href="../../../assets/docs/Aviso_de_Privacidad.pdf" target="_blank">Términos y condiciones</a>
        </div>
        <div class="anchors_right">
          <a [routerLink]="['/pacientes']">Medpago para pacientes</a>
          <a [routerLink]="['/clinicas']">Mepago para prescriptores de servicios</a>
          <span (click)="openPop()">Contactar con nosotros</span>
        </div>
      </div>
     </div>
     <div class="separator"></div>
     <div class="copyright">
      <span>© 2023 Medpago.</span><span>Todos los derechos reservados.</span>
     </div>
    </div>
</footer>
