import { inject } from '@angular/core';
import { ContentfulService } from '../services/contentful.service';
import { CanActivateFn } from '@angular/router';

export const getPatients: CanActivateFn = async () => {
  const content = inject(ContentfulService);

  try {
    await content.getPatients();
    await content.getServices();
  } catch (error) {
    console.warn(error);
    return false;
  } finally {
    return true;
  }
};
