import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from "@angular/core";
import { ContentfulService } from "../services/contentful.service";
import { isPlatformBrowser } from "@angular/common";

@Component({
  selector: 'app-clinics',
  templateUrl: './clinics.component.html',
  styleUrls: ['./clinics.component.scss']
})
export class ClinicsComponent implements OnInit {
title = 'medpago_clinics'

content: { [key: string] : any } = {}

popIsOpen: boolean;
popContactIsOpen: boolean;

constructor(@Inject(PLATFORM_ID) private platform: Object, private renderer: Renderer2, private contentfulService: ContentfulService) {
  this.popIsOpen = false;
  this.popContactIsOpen = false;
}
ngOnInit(): void {
  let data = this.contentfulService.clinics

  if (data) {
    data.forEach(section => this.content[section.name] = section)
  }
}

openPop() {
  this.renderer.addClass(document.body, 'overflow_hidden')
  this.popIsOpen = true;
  if (isPlatformBrowser(this.platform)) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

}

openPopContact() {
  this.renderer.addClass(document.body, 'overflow_hidden')
  this.popContactIsOpen = true;
  if (isPlatformBrowser(this.platform)) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }
}

closePop() {
  this.popIsOpen = false
  this.renderer.removeClass(document.body, 'overflow_hidden')
  if (isPlatformBrowser(this.platform)) {
  }
}

closePopContact() {
  this.popContactIsOpen = false;
  this.renderer.removeClass(document.body, 'overflow_hidden')
  if (isPlatformBrowser(this.platform)) {
  }
}

navigateToDoctors() {

  if (isPlatformBrowser(this.platform)) {
    const forDoctors = document.getElementById('forDoctors');
    if (forDoctors) {
      forDoctors.scrollIntoView({ behavior: 'smooth'})
    }
  }

}
}
