import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-medical-network',
  templateUrl: './medical-network.component.html',
  styleUrls: ['./medical-network.component.scss']
})
export class MedicalNetworkComponent implements OnInit{

  @Input() content: {
    title: string,
    subtitle: string,
    usersText: string,
    usersNumber: string,
    usersImage: {fields: {file: {url: ''}}},
    doctorsText: string,
    doctorsNumber: string,
    doctorsImage: {fields: {file: {url: ''}}},
    clinicsText: string,
    clinicsNumber: string,
    clinicsImage: {fields: {file: {url: ''}}}
  } = {
    title: '',
    subtitle: '',
    usersText: '',
    usersNumber: '',
    usersImage: {fields: {file: {url: ''}}},
    doctorsText: '',
    doctorsNumber: '',
    doctorsImage: {fields: {file: {url: ''}}},
    clinicsText: '',
    clinicsNumber: '',
    clinicsImage: {fields: {file: {url: ''}}}
  }

  title = '';
  subtitle = '';
  usersText = '';
  usersNumber = '';
  usersImage = '';
  doctorsText = '';
  doctorsNumber = '';
  doctorsImage = '';
  clinicsText = '';
  clinicsNumber = '';
  clinicsImage = '';

  constructor(){}

  ngOnInit(): void {
    this.title = this.content.title;
    this.subtitle = this.content.subtitle;
    this.usersText = this.content.usersText;
    this.usersNumber = this.content.usersNumber;
    this.usersImage = this.content.usersImage.fields.file.url;
    this.doctorsText = this.content.doctorsText;
    this.doctorsNumber = this.content.doctorsNumber;
    this.doctorsImage = this.content.doctorsImage.fields.file.url;
    this.clinicsText = this.content.clinicsText;
    this.clinicsNumber = this.content.clinicsNumber;
    this.clinicsImage = this.content.clinicsImage.fields.file.url;
  }

  }
