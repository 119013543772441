export const environment = {
  production: false,
  envName: 'dev',
  email: {
    user: 'soporte@talentomobile.com',
    pass: 'vhxcxdimfnjdnozm',
    to: 'pedro.ortega@talentomobile.com',
    apiBaseRoute: 'https://dev.medpago.kn.talentomobile.com/api/email/',
    bbc: ['pedro.ortega@talentomobile.com', 'gustavo.zapico@talentomobile.com'],
  },

  newsletter: {
    apiKey: '28e621008f23478168e45a5a9206fc54-us21',
    addUrl: 'https://us21.api.mailchimp.com/3.0/lists/d59433579c/members',
    apiBaseRoute: 'https://dev.medpago.kn.talentomobile.com/api/newsletter',
  },

  content: {
    spaceId: '1m9v94tfuhjh',
    accessToken: '3oO7i5sLgD8G_wgfFhTgZSHbzROE6QBX3rMieQgppkc',
    apiBaseRoute: 'https://dev.medpago.kn.talentomobile.com/api/content',
  },
};
