import { inject } from '@angular/core';
import { ContentfulService } from '../services/contentful.service';
import { CanActivateFn } from '@angular/router';

export const getDoctors: CanActivateFn = async () => {

    const content = inject(ContentfulService);


    try {
      await content.getDoctors();
    } catch (error) {
      console.warn(error)
      return false
    } finally {
      return true
    }


}
