import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-trust-us',
  templateUrl: './trust-us.component.html',
  styleUrls: ['./trust-us.component.scss']
})
export class TrustUsComponent implements OnInit{

  @Input() content: {
    title: string,
    image1: {fields: {file: {url: string}}},
    image2: {fields: {file: {url: string}}}
  } = {title: '', image1: {fields: {file: {url: ''}}}, image2: {fields: {file: {url: ''}}}}

  title = '';
  image1 = '';
  image2 = '';

ngOnInit(): void {
    this.title = this.content.title;
    this.image1 = this.content.image1.fields.file.url;
    this.image2 = this.content.image2.fields.file.url;
}
}
