<section class="increase">
  <h2 class="title">{{title}}</h2>
  <div class="box">
    <div class="background">
      <div class="glass_layer"></div>
      <div class="sphere1_top_layer"></div>
      <div class="sphere2_top_layer"></div>
    </div>
    <div class="info">
      <div class="image">
        <img [src]="image1" alt="">
      </div>
      <div class="text">
        <h3>{{subtitle1}}</h3>
        <p>{{text1}}</p>
      </div>
    </div>
    <div class="info">
      <div class="image">
        <img [src]="image2" alt="">
      </div>
      <div class="text">
        <h3>{{subtitle2}}</h3>
        <p>{{text2}}</p>
      </div>
    </div>
  </div>
  <div class="sphere1"></div>
  <div class="sphere2"></div>
</section>
