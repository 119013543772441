import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Observable, Subscription, fromEvent } from 'rxjs';

@Component({
  selector: 'app-evolves',
  templateUrl: './evolves.component.html',
  styleUrls: ['./evolves.component.scss']
})
export class EvolvesComponent implements OnInit{

  @Input() content: {
    percentage1: string,
    type1: string,
    text1: string,
    percentage2: string,
    type2: string,
    text2: string,
    percentage3: string,
    type3: string,
    text3: string,
    title: string,
    subtitle: string
  } = {
    percentage1: '',
    type1: '',
    text1: '',
    percentage2: '',
    type2: '',
    text2: '',
    percentage3: '',
    type3: '',
    text3: '',
    title: '',
    subtitle: ''
  }

  percentage1 = '';
  type1 = '';
  text1 = '';
  percentage2 = '';
  type2 = '';
  text2 = '';
  percentage3 = '';
  type3 = '';
  text3 = '';
  title = '';
  subtitle = '';

  isMd: boolean = false;
  resizeObs$!: Observable<Event>;
  resizeSubs$!: Subscription;

constructor(@Inject(PLATFORM_ID) private platformId: Object){}

ngOnInit(): void {
  this.percentage1 = this.content.percentage1;
  this.type1 = this.content.type1;
  this.text1 = this.content.text1;
  this.percentage2 = this.content.percentage2;
  this.type2 = this.content.type2;
  this.text2 = this.content.text2;
  this.percentage3 = this.content.percentage3;
  this.type3 = this.content.type3;
  this.text3 = this.content.text3;
  this.title = this.content.title;
  this.subtitle = this.content.subtitle;

  if (isPlatformBrowser(this.platformId)) {
    if (window.innerWidth <= 414) {
      this.isMd = false
    } else {
      this.isMd = true
    }
    this.resizeObs$ = fromEvent(window, 'resize');
    this.resizeSubs$ = this.resizeObs$.subscribe(e => {
     if (window.innerWidth <= 414) {
       this.isMd = false
     } else {
       this.isMd = true
     }
    })
  }
}

}
