import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit{

@Input() content: {
  title: string,
  subtitle: string,
  title1: string,
  text1: string,
  image1: {fields: {file: {url: string}}},
  title2: string,
  text2: string,
  image2: {fields: {file: {url: string}}},
  title3: string,
  text3: string,
  image3: {fields: {file: {url: string}}}
} = {
  title: '',
  subtitle: '',
  title1: '',
  text1: '',
  image1: {fields: {file: {url: ''}}},
  title2: '',
  text2: '',
  image2: {fields: {file: {url: ''}}},
  title3: '',
  text3: '',
  image3: {fields: {file: {url: ''}}}
}

title = '';
subtitle = '';
title1 = '';
text1 = '';
image1 = '';
title2 = '';
text2 = '';
image2 = '';
title3 = '';
text3 = '';
image3 = '';

ngOnInit(): void {
  this.title = this.content.title;
  this.subtitle = this.content.subtitle;
  this.title1 = this.content.title1;
  this.text1 = this.content.text1;
  this.image1 = this.content.image1.fields.file.url;
  this.title2 = this.content.title2;
  this.text2 = this.content.text2;
  this.image2 = this.content.image2.fields.file.url;
  this.title3 = this.content.title3;
  this.text3 = this.content.text3;
  this.image3 = this.content.image3.fields.file.url;
}
}
