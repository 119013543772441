import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  Inject,
  PLATFORM_ID,
  Input,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
})
export class HeroComponent implements OnInit, OnDestroy {
  @Output() toOpen = new EventEmitter();
  @Output() navigate = new EventEmitter();
  @Input() content: {
    title: string;
    subtitle: string;
    icon: { fields: { file: { url: string } } };
    anchorToPop: string;
    anchorToSectionForDoctors: string;
    image1: { fields: { file: { url: string } } };
    image2: { fields: { file: { url: string } } };
    image3: { fields: { file: { url: string } } };
  } = {
    title: '',
    subtitle: '',
    icon: { fields: { file: { url: '' } } },
    anchorToPop: '',
    anchorToSectionForDoctors: '',
    image1: { fields: { file: { url: '' } } },
    image2: { fields: { file: { url: '' } } },
    image3: { fields: { file: { url: '' } } },
  };

  title = '';
  subtitle = '';
  icon = '';
  anchorToPop = '';
  anchorToSectionForDoctors = '';
  listOfPortraits: string[] = [''];

  invisibleImgIndex$: BehaviorSubject<number>;
  portraitSubscription!: Subscription;

  isPatientSection: boolean = true;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: ActivatedRoute
  ) {
    this.invisibleImgIndex$ = new BehaviorSubject<number>(0);
  }

  ngOnInit(): void {
    this.title = this.content.title;
    this.subtitle = this.content.subtitle;
    this.icon = this.content.icon.fields.file.url;
    this.anchorToPop = this.content.anchorToPop;
    this.anchorToSectionForDoctors = this.content.anchorToSectionForDoctors;
    this.listOfPortraits = [
      this.content.image1.fields.file.url,
      this.content.image2?.fields.file.url,
      this.content.image3?.fields.file.url,
    ].filter((value) => value !== undefined);

    if (isPlatformBrowser(this.platformId) && this.listOfPortraits.length > 1) {
      this.portraitSubscription = interval(10000).subscribe(() => {
        this.changePortrait();
      });
    }

    if (this.route.snapshot.routeConfig?.path === 'pacientes') {
      this.isPatientSection = true;
    } else {
      this.isPatientSection = false;
    }
  }

  ngOnDestroy(): void {
    if (this.portraitSubscription) {
      this.portraitSubscription.unsubscribe();
    }
  }

  openPop() {
    this.toOpen.emit();
  }

  navigateFn() {
    this.navigate.emit();
  }

  changePortrait() {
    if (this.invisibleImgIndex$.value < this.listOfPortraits.length - 1) {
      this.invisibleImgIndex$.next(this.invisibleImgIndex$.value + 1);
    } else {
      this.invisibleImgIndex$.next(0);
    }
  }
}
