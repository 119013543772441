import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Service } from 'src/app/interfaces/Service';
import { ContentfulService } from 'src/app/services/contentful.service';

@Component({
  selector: 'app-centers',
  templateUrl: './centers.component.html',
  styleUrls: ['./centers.component.scss'],
})
export class CentersComponent implements OnInit {
  @Output() toOpen = new EventEmitter();
  @Input() content: {
    title: string;
    subtitle: string;
    center1Image: { fields: { file: { url: string } } };
    center1List: string[];
    center2Image: { fields: { file: { url: string } } };
    center2List: string[];
    services: string[];
    label: string;
    inputPlaceholder: string;
    anchorText: string;
    icon1: { fields: { file: { url: string } } };
    icon2: { fields: { file: { url: string } } };
    icon3: { fields: { file: { url: string } } };
    icon4: { fields: { file: { url: string } } };
    notFoundText: string;
  } = {
    title: '',
    subtitle: '',
    center1Image: { fields: { file: { url: '' } } },
    center1List: [],
    center2Image: { fields: { file: { url: '' } } },
    center2List: [],
    services: [],
    label: '',
    inputPlaceholder: '',
    anchorText: '',
    icon1: { fields: { file: { url: '' } } },
    icon2: { fields: { file: { url: '' } } },
    icon3: { fields: { file: { url: '' } } },
    icon4: { fields: { file: { url: '' } } },
    notFoundText: '',
  };

  title = '';
  subtitle = '';
  center1Image = '';
  center1List: string[] = [];
  center2Image = '';
  center2List: string[] = [];
  services: string[] = [];
  label = '';
  inputPlaceholder = '';
  anchorText = '';
  icon1 = '';
  icon2 = '';
  icon3 = '';
  icon4 = '';
  notFoundText = '';

  list$: BehaviorSubject<Service[]>;

  list: Service[] = [];

  input = new FormControl('');

  constructor(private contentfulService: ContentfulService) {
    this.list$ = new BehaviorSubject<Service[]>([]);

    let data = this.contentfulService.services;

    if (data) {
      data.forEach((category) => {
        if (category.services) {
          category.services.forEach((service: string) => {
            this.list.push({
              name: service,
              icon: category.icon.fields.file.url,
            });
          });
        }
      });
    }
  }

  ngOnInit(): void {
    this.title = this.content.title;
    this.subtitle = this.content.subtitle;
    this.center1Image = this.content.center1Image.fields.file.url;
    this.center1List = this.content.center1List;
    this.center2Image = this.content.center2Image.fields.file.url;
    this.center2List = this.content.center2List;
    this.services = this.content.services;
    this.label = this.content.label;
    this.inputPlaceholder = this.content.inputPlaceholder;
    this.anchorText = this.content.anchorText;
    this.icon1 = this.content.icon1.fields.file.url;
    this.icon2 = this.content.icon2.fields.file.url;
    this.icon3 = this.content.icon3.fields.file.url;
    this.icon4 = this.content.icon4.fields.file.url;
    this.notFoundText = this.content.notFoundText;
  }

  search(e: string) {
    if (e !== '') {
      return this.list.filter((option) =>
        option.name.toLowerCase().includes(e.toLowerCase())
      );
    } else {
      return [];
    }
  }

  openPop() {
    this.toOpen.emit();
  }
}
