import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, fromEvent } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{

@Output() navigate = new EventEmitter();

@Input() content: {
  textCenter: string, textRight: '', logo: {fields: {file: {url: ''}}}, logoMobile: {fields: {file: {url: ''}}}
} = {textCenter: '', textRight: '', logo: {fields: {file: {url: ''}}}, logoMobile: {fields: {file: {url: ''}}}}

textCenter = '';
textRight = '';
logo = '';
logoMobile = '';

isPatientSection: boolean = true;
isMd: boolean = false;
resizeObs$!: Observable<Event>;
resizeSubs$!: Subscription;

constructor(@Inject(PLATFORM_ID) private platformId: Object, private route: ActivatedRoute) {}

ngOnInit(): void {
  this.textCenter = this.content.textCenter
  this.textRight = this.content.textRight;
  this.logo = this.content.logo.fields.file.url;
  this.logoMobile = this.content.logoMobile.fields.file.url

  if (isPlatformBrowser(this.platformId)) {
    if (this.route.snapshot.routeConfig?.path === 'pacientes') {
      this.isPatientSection = true
    } else {
      this.isPatientSection = false
    }
  }

  if (isPlatformBrowser(this.platformId)) {
    if (window.innerWidth <= 414) {
      this.isMd = false
    } else {
      this.isMd = true
    }
    this.resizeObs$ = fromEvent(window, 'resize');
    this.resizeSubs$ = this.resizeObs$.subscribe(e => {
     if (window.innerWidth <= 414) {
       this.isMd = false
     } else {
       this.isMd = true
     }
    })
  }
}

navigateFn() {
  this.navigate.emit();
}

}
