import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-for-doctors',
  templateUrl: './for-doctors.component.html',
  styleUrls: ['./for-doctors.component.scss']
})
export class ForDoctorsComponent implements OnInit{

  @Input() content: {
    title: string,
    subtitle: string,
    image: {fields: {file: {url: string}}},
    textLeft: string,
    imageLeft: {fields: {file: {url: string}}},
    buttonLeft: string,
    textRight: string,
    imageRight: {fields: {file: {url: string}}},
    buttonRight: string
  } = {
    title: '',
    subtitle: '',
    image: {fields: {file: {url: ''}}},
    textLeft: '',
    imageLeft: {fields: {file: {url: ''}}},
    buttonLeft: '',
    textRight: '',
    imageRight: {fields: {file: {url: ''}}},
    buttonRight: ''
  }

  title = '';
  subtitle = '';
  image = '';
  textLeft = '';
  imageLeft = '';
  buttonLeft = '';
  textRight = '';
  imageRight = '';
  buttonRight = '';

  constructor(private router: Router, private route: ActivatedRoute){}

  ngOnInit(): void {
    this.title = this.content.title;
    this.subtitle = this.content.subtitle;
    this.image = this.content.image.fields.file.url;
    this.textLeft = this.content.textLeft;
    this.imageLeft = this.content.imageLeft.fields.file.url;
    this.buttonLeft = this.content.buttonLeft;
    this.textRight = this.content.textRight;
    this.imageRight = this.content.imageRight.fields.file.url;
    this.buttonRight = this.content.buttonRight;
  }

  toLeft() {
    if (this.route.snapshot.routeConfig?.path !== 'clinicas') {
      this.router.navigate(['/clinicas'])
    } else {
      this.router.navigate(['/medicos'])
    }

  }

  toRight() {
    if (this.route.snapshot.routeConfig?.path !== 'pacientes') {
      this.router.navigate(['/pacientes'])
    } else {
      this.router.navigate(['/medicos'])
    }

  }
}
