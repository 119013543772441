<section class="for_doctors" id=forDoctors>
  <div class="container">
    <div class="sphere1_bottom_layer"></div>
    <div class="background">
      <div class="glass_layer"></div>
      <div class="sphere1_top_layer"></div>
      <div class="sphere2_bottom_layer"></div>
    </div>
    <div class="flexbox">
      <div class="info">
        <h2>{{title}}</h2>
        <p>{{subtitle}}</p>
        <div class="anchors">
          <div class="box box_left">
            <div class="box_background">
              <div class="box_glass_layer"></div>
              <div class="sphere2_top_layer_left"></div>
            </div>
            <h3>{{textLeft}}</h3>
            <div class="image">
              <img [src]="imageLeft" alt="">
            </div>
            <button (click)="toLeft()">{{buttonLeft}}</button>
          </div>
          <div class="box box_right">
            <div class="box_background">
              <div class="box_glass_layer"></div>
              <div class="sphere2_top_layer_right"></div>
              <div class="sphere1_top_layer_right"></div>
            </div>
            <h3>{{textRight}}</h3>
            <div class="image">
              <img [src]="imageRight" alt="">
            </div>
            <button (click)="toRight()">{{buttonRight}}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="image_right">
      <img [src]="image" alt="">
    </div>
  </div>
</section>
