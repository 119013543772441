import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NodemailerService } from 'src/app/services/nodemailer.service';

@Component({
  selector: 'app-pop-up-patients',
  templateUrl: './pop-up-patients.component.html',
  styleUrls: ['./pop-up-patients.component.scss']
})
export class PopUpPatientsComponent {

  @Output() toClose = new EventEmitter();

  readonly form = new FormGroup({
    username: new FormControl('', [
      Validators.required,
    Validators.pattern(/^[A-Za-záéíóúÁÉÍÓÚüÜñÑ]+(?: [A-Za-záéíóúÁÉÍÓÚüÜñÑ]+)+$/)]),
    email: new FormControl('', [
      Validators.required,
    Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]),
    phone: new FormControl('', [
      Validators.required,
    Validators.pattern(/^(\+\d{1,3}\s?)?(\(\+?\d{1,4}\)\s?)?(\d{10})$/)])
  })

  loading: boolean = false;

  activatedUsername = false;
  activatedEmail = false;
  activatedPhone = false;

  emailSended = false;

  constructor(private nodemailer: NodemailerService){}

  onSubmit() {
    this.loading = true;
    this.nodemailer.sendEmail({username: this.form.value.username, email: this.form.value.email, phone: this.form.value.phone, subject: 'Formulario Pacientes'}).subscribe(data => {
      console.log(data);
    }, err => console.log(err), () => this.loading = false)

    this.emailSended = true;
  }

  closePop() {
    this.toClose.emit();
  }

  activateLabel(labelName: string) {
    switch (labelName) {
      case 'username': this.activatedUsername = true;
      break;
      case 'email': this.activatedEmail = true;
      break;
      case 'phone': this.activatedPhone = true;
      break;
    }
  }

  desactivateLabel() {
    this.activatedUsername = false;
    this.activatedEmail = false;
    this.activatedPhone = false;
  }
}
