import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss']
})
export class BenefitsComponent implements OnInit{

  @Input() content: {
    titleTop: string,
    image1: {fields: {file: {url: string}}},
    subtitle1: string,
    text1: string,
    image2: {fields: {file: {url: string}}},
    subtitle2: string,
    text2: string,
    image3: {fields: {file: {url: string}}},
    subtitle3: string,
    text3: string,
    titleBottom: string,
    percentage1: string,
    textBottom1: string,
    percentage2: string,
    textBottom2: string
  } = {
    titleTop: '',
    image1: {fields: {file: {url: ''}}},
    subtitle1: '',
    text1: '',
    image2: {fields: {file: {url: ''}}},
    subtitle2: '',
    text2: '',
    image3: {fields: {file: {url: ''}}},
    subtitle3: '',
    text3: '',
    titleBottom: '',
    percentage1: '',
    textBottom1: '',
    percentage2: '',
    textBottom2: ''
  }

    titleTop = '';
    image1 = '';
    subtitle1 = '';
    text1 = '';
    image2 = '';
    subtitle2 = '';
    text2 = '';
    image3 = '';
    subtitle3 = '';
    text3 = '';
    titleBottom = '';
    percentage1 = '';
    textBottom1 = '';
    percentage2 = '';
    textBottom2 = '';

    constructor(){}

    ngOnInit(): void {
      this.titleTop = this.content.titleTop;
      this.image1 = this.content.image1.fields.file.url;
      this.subtitle1 = this.content.subtitle1;
      this.text1 = this.content.text1;
      this.image2 = this.content.image2.fields.file.url;
      this.subtitle2 = this.content.subtitle2;
      this.text2 = this.content.text2;
      this.image3 = this.content.image3.fields.file.url;
      this.subtitle3 = this.content.subtitle3;
      this.text3 = this.content.text3;
      this.titleBottom = this.content.titleBottom;
      this.percentage1 = this.content.percentage1;
      this.textBottom1 = this.content.textBottom1;
      this.percentage2 = this.content.percentage2;
      this.textBottom2 = this.content.textBottom2;

    }
}
