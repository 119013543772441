<section class="centers">
  <article class="top">
    <div class="background">
      <div class="sphere1"></div>
      <div class="sphere2"></div>
    </div>
    <div class="content_top">
       <div class="titles">
        <h2>{{title}}</h2>
        <h3>{{subtitle}}</h3>
      </div>
       <div class="boxes">
         <div class="box box_top">
          <div class="logo">
            <img [src]="center1Image" alt="">
          </div>
          <ul>
            <li  *ngFor="let item of center1List">{{item}}</li>
          </ul>
        </div>
         <div class="box box_bottom">
          <div class="logo">
            <img [src]="center2Image" alt="">
          </div>
          <ul>
            <li *ngFor="let item of center2List">{{item}}</li>
          </ul>
        </div>
       </div>
     </div>
  </article>
  <article class="bottom">
     <div class="background">
      <div class="sphere1"></div>
      <div class="sphere2"></div>
    </div>
    <div class="content_bottom">
      <div class="box">
        <ul>
          <li><img [src]="icon1" alt="">{{services[0]}}</li>
          <li><img [src]="icon2" alt="">{{services[1]}}</li>
          <li><img [src]="icon3" alt="">{{services[2]}}</li>
          <li><img [src]="icon4" alt="">{{services[3]}}</li>
        </ul>
      </div>
      <div class="interactive">
        <div class="search">
          <label for="">{{label}}</label>
          <input type="text" [placeholder]="inputPlaceholder" #services_offer_input (ngModelChange)="list$.next(search($event))" [formControl]="input">
          <div class="input_container" [ngClass]=" input.value === '' ? 'hidden_autocomplete' : ''">
            <div class="autocomplete_list">
              <ul>
                <li class="not_found_services" (click)="openPop()">
                  <div class="image">
                    <img src="../../../assets/icons/not_found_icon.svg" alt="">
                  </div>
                  <span class="text">{{notFoundText}}</span>
                </li>
                <ng-container *ngFor="let item of list$ | async">
                  <li> <div class="image">
                    <img [src]="item.icon" alt="">
                  </div><span class="text">{{item.name}}</span></li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
        <div class="downloads">
          <span>{{anchorText}}</span>
          <div class="anchors">
            <a href="https://www.apple.com/es/app-store/" target="_blank" class="apple">
              <img src="../../../assets/images/button_ios.svg" alt="" >
            </a>

            <a href="https://play.google.com/store/apps" target="_blank" class="google">
              <img src="../../../assets/images/button_go.svg" alt="" >
            </a>
          </div>
        </div>
      </div>
    </div>
  </article>
</section>
