import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedicalNetworkComponent } from './components/medical-network/medical-network.component';
import { CentersComponent } from './components/centers/centers.component';
import { YourNeedsComponent } from './components/your-needs/your-needs.component';
import { StepsComponent } from './components/steps/steps.component';
import { AnchorComponent } from './components/anchor/anchor.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { PatientsComponent } from './patients.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { PopUpPatientsComponent } from './components/pop-up-patients/pop-up-patients.component';



@NgModule({
  declarations: [
    MedicalNetworkComponent,
    CentersComponent,
    YourNeedsComponent,
    StepsComponent,
    AnchorComponent,
    PatientsComponent,
    PopUpPatientsComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    SharedModule
  ],
  providers: [],
  exports: [
    PatientsComponent
  ],
  bootstrap: [PatientsComponent]
})
export class PatientsModule { }
