<section class="steps">
  <div class="titles">
    <h2>{{title}}</h2>
    <h3>{{subtitle}}</h3>
  </div>
  <div class="flexbox">
    <div class="step">
      <div class="info">
        <span>Paso 1</span>
        <h4>{{title1}}</h4>
        <p>{{text1}}</p>
      </div>
      <div class="image">
        <img [src]="image1" alt="">
      </div>
    </div>
    <div class="step step_reverse">
      <div class="info">
        <span>Paso 2</span>
        <h4>{{title2}}</h4>
        <p>{{text2}}</p>
      </div>
      <div class="image">
        <img [src]="image2" alt="">
      </div>
    </div>
    <div class="step">
      <div class="info">
        <span>Paso 3</span>
        <h4>{{title3}}</h4>
        <p>{{text3}}</p>
      </div>
      <div class="image">
        <img [src]="image3" alt="">
      </div>
    </div>
  </div>
</section>
