<section class="evolves">
  <div class="titles" *ngIf="!isMd">
    <h2 >{{title}}</h2>
    <h3>{{subtitle}}</h3>
  </div>
  <div class="boxes">
    <div class="box box1">
      <span class="percentage">{{percentage1}}</span>
      <h4>{{type1}}</h4>
      <span class="text">{{text1}}</span>
    </div>
    <div class="box box2">
      <span class="percentage">{{percentage2}}</span>
      <h4>{{type2}}</h4>
      <span class="text">{{text2}}</span>
    </div>
    <div class="box box3">
      <span class="percentage">{{percentage3}}</span>
      <h4>{{type3}}</h4>
      <span class="text">{{text3}}</span>
    </div>
  </div>
  <div class="titles" *ngIf="isMd">
    <h2>{{title}}</h2>
    <h3>{{subtitle}}</h3>
  </div>
</section>
