import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-anchor',
  templateUrl: './anchor.component.html',
  styleUrls: ['./anchor.component.scss']
})
export class AnchorComponent implements OnInit{

  @Output() toOpen = new EventEmitter();
  @Input() content: {
    titleLeft: string,
    textLeft: string,
    titleRight: string,
    textRight: string,
    textButton: string
  } = {
    titleLeft: '',
    textLeft: '',
    titleRight: '',
    textRight: '',
    textButton: ''
  }

  titleLeft = '';
  textLeft = '';
  titleRight = '';
  textRight = '';
  textButton = '';

  constructor(){}


ngOnInit(): void {
  this.titleLeft = this.content.titleLeft;
  this.textLeft = this.content.textLeft;
  this.titleRight = this.content.titleRight;
  this.textRight = this.content.textRight;
  this.textButton = this.content.textButton;
}

  openPop() {
    this.toOpen.emit();
  }
}
