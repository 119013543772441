<app-header (navigate)="navigateToDoctors()" [content]="content['Header Doctors']"></app-header>
<app-hero (toOpen)="openPop()" (navigate)="navigateToDoctors()" [content]="content['Hero Doctors']"></app-hero>
<app-evolves [content]="content['Evolves']"></app-evolves>
<app-video [content]="content['Video Doctors']" (toOpen)="openPop()"></app-video>
<app-increase [content]="content['Increase']"></app-increase>
<app-join-us [content]="content['Join Us Doctors']" (toOpen)="openPop()"></app-join-us>
<app-for-doctors [content]="content['Navigation Doctors']"></app-for-doctors>
<app-footer (toOpenContact)="openPopContact()" [content]="content['Footer Doctors']"></app-footer>
<app-pop-up-doctors *ngIf="popIsOpen" (toClose)="closePop()"></app-pop-up-doctors>
<app-pop-up-contact *ngIf="popContactIsOpen" (toCloseContact)="closePopContact()"></app-pop-up-contact>
