<section class="video">
  <div class="section_container">
    <div class="video_container" *ngIf="false">
      <video #videoPlayer class="video_player" width="100%" height="auto" >
        <source src="">
        Your browser does not support the video tag.
      </video>
      <button class="play_button"></button>
    </div>

    <div style="margin-top: 5vw; width: 100%; height: 100%; position:relative;">
      <div class="poster" *ngIf="!isPlay1 && isMd" (click)="playVimeo1()">
        <img src="../../../../assets/images/video_poster.png" alt=""  class="placeholder">
        <img src="../../../../assets/images/video_button.png" alt="" class="play_button">
      </div>
      <div class="poster_mobile" *ngIf="!isPlay2 && !isMd" (click)="playVimeo2()">
        <!-- Comentamos lo siguiente y repetimos la imagen de desktop porque ahora no hay video para mobile. Los videos en mobile son los mismos pero hemos aumentado su tamaño al 150% en vez de estar al 100% y para corregir su posición hemos pasado left de 0 a 25%-->
        <!-- <img src="../../../../assets/images/video_poster_mobile.png" alt="" class="placeholder"> -->
        <img src="../../../../assets/images/video_poster.png" alt=""  class="placeholder">
        <img src="../../../../assets/images/video_button.png" alt="" class="play_button" >
      </div>
      <div *ngIf="route.snapshot.routeConfig?.path === 'clinicas'">
        <iframe src="https://player.vimeo.com/video/896147596?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:-25%;left:-15%;width:130%;height:130%;" title="MEDPAGO_CENTROS_MEDICOS_v3" *ngIf="isMd && isPlay1" #vimeoVideo1 ></iframe>
        <iframe src="https://player.vimeo.com/video/896147596?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:-10%;left:-35%;width:170%;height:170%;" title="MEDPAGO_CENTROS_MEDICOS_v3" *ngIf="!isMd && isPlay2" #vimeoVideo2 ></iframe>
      </div>
      <div *ngIf="route.snapshot.routeConfig?.path === 'pacientes'">
        <iframe src="https://player.vimeo.com/video/896145968?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:-25%;left:-15%;width:130%;height:130%;" title="MEDPAGO_Pacientes_v2 (1)" *ngIf="isMd && isPlay1" #vimeoVideo1 ></iframe>
        <iframe src="https://player.vimeo.com/video/896145968?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:-10%;left:-35%;width:170%;height:170%;" title="MEDPAGO_Pacientes_v2 (1)" *ngIf="!isMd && isPlay2" #vimeoVideo2 ></iframe>
      </div>
      <div *ngIf="route.snapshot.routeConfig?.path === 'medicos'">
        <iframe src="https://player.vimeo.com/video/896146919?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:-25%;left:-15%;width:130%;height:130%;" title="MEDPAGO_DOCTORES_v3" *ngIf="isMd && isPlay1" #vimeoVideo1 ></iframe>
        <iframe src="https://player.vimeo.com/video/896146919?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:-10%;left:-35%;width:170%;height:170%;" title="MEDPAGO_DOCTORES_v3" *ngIf="!isMd && isPlay2" #vimeoVideo2 ></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>

    <article class="text" #articlePrueba>
      <div>
        <span>{{title}}</span>
      </div>
      <button (click)="openPop()">{{button}}</button>
    </article>
  </div>
</section>

<!-- *ngIf="isMd && isPlay" -->
