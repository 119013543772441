import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'medpago';

  ngOnInit(): void {
      if (!environment.production) {
          console.log(`Entorno de desarrollo. ${environment.envName}`)
      }
  }
}
