<section class="pop_up">
    <div class="form" *ngIf="!emailSended">
      <div class="close_icon">
        <div class="image">
          <img src="../../../assets/icons/close.svg" alt="" (click)="closePop()">
        </div>
      </div>
      <h2>Rellena el formulario<br>y nos pondremos en contacto contigo</h2>
      <form [formGroup]="form" (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit()">
        <div class="top">
          <div class="username">
            <label for="username" [ngClass]="activatedUsername || form.get('username')?.value !== '' ? 'activated_label' : ''">Nombre y apellidos*</label>
            <input type="text" formControlName="username" name="username" id="username" (click)="activateLabel('username')" (blur)="desactivateLabel()">
          </div>
        </div>
        <div class="bottom">
          <div class="email">
            <label for="email" [ngClass]="activatedEmail || form.get('email')?.value !== '' ? 'activated_label' : ''">Email*</label>
            <input type="text" formControlName="email" name="email" id="email" (click)="activateLabel('email')" (blur)="desactivateLabel()">
          </div>
          <div class="phone">
            <label for="phone" [ngClass]="activatedPhone || form.get('phone')?.value !== '' ? 'activated_label' : ''">Celular*</label>
            <input type="text" formControlName="phone" name="phone" id="phone" (click)="activateLabel('phone')" (blur)="desactivateLabel()">
          </div>
        </div>
        <button type="submit" [disabled]="!form.valid">Enviar</button>
      </form>
    </div>
    <div class="completed" *ngIf="emailSended">
      <div class="close_icon">
        <div class="image">
          <img src="../../../assets/icons/close.svg" alt="" (click)="closePop()">
        </div>
      </div>
      <h2>El formulario ha sido enviado con éxito</h2>
      <h3>Nos pondremos en contacto contigo lo antes posible</h3>
      <div class="icon">
        <img src="../../../assets/icons/success.png" alt="">
      </div>
      <span>Descarga la app Medpago</span>
      <div class="anchors">
        <a href="https://www.apple.com/es/app-store/" target="_blank" class="apple">
          <img src="../../../assets/images/button_ios.svg" alt="" >
        </a>

        <a href="https://play.google.com/store/apps" target="_blank" class="google">
          <img src="../../../assets/images/button_go.svg" alt="" >
        </a>
      </div>
    </div>
</section>
