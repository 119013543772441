<div class="hero" [ngClass]="isPatientSection ? 'background_patients align_patients' : 'background_professionals align_pros'">
  <div class="info">
    <div class="titles">
     <div class="title">
      <h1 [ngClass]="isPatientSection ? 'title_patients' : 'title_pros'">{{title}}</h1>
     </div>
     <div class="subtitle">
      <h2 [ngClass]="isPatientSection ? 'subtitle_patients' : 'subtitle_pros'">{{subtitle}}</h2>
     </div>
    </div>
    <div class="info_bottom" [ngClass]="isPatientSection ? 'info_bottom_patients' : 'info_bottom_pro'">
    <div [ngClass]="isPatientSection ? 'trustpilot' : 'truspilot_pros'" *ngIf="false">

      <img src="../../../assets/images/trustpilot_logo.svg" alt="Trustpilot Logo" class="trustpilot_logo">

      <img src="../../../assets/images/trustpilot_stars.svg" alt="Trustpilot Score" class="trustpilot_stars">

      <div class="trustpilot_scores">
        <div class="score_container">
          <span class="text">
            TrustScore
          </span>
         <span class="number">
          5.0
         </span>
        </div>
        <div class="separator"></div>
        <div class="score_container">
         <span class="number">
          16,002
         </span>
         <span class="text">
          reviews
         </span>
        </div>
      </div>
    </div>
    <!-- <app-trustpilot-box></app-trustpilot-box> -->

    <div class="anchors">
      <div class="downloads" *ngIf="isPatientSection">

          <a href="https://www.apple.com/es/app-store/" target="_blank" class="apple">
            <img src="../../../assets/images/button_ios.svg" alt="" >
          </a>

          <a href="https://play.google.com/store/apps" target="_blank" class="google">
            <img src="../../../assets/images/button_go.svg" alt="" >
          </a>

       </div>
       <div [ngClass]="isPatientSection ? 'more_info_patients' : 'more_info_pro'" (click)="openPop()">
        <span [ngClass]="isPatientSection ? 'text_info_patients' : 'text_info_pro'">{{anchorToPop}}</span>

          <img src="../../../assets/icons/arrow_toright.svg" alt="" class="icon_info" *ngIf="isPatientSection">

       </div>
    </div>
  </div>
  </div>
  <div class="image">
    <img [src]="isPatientSection ? '../../../assets/images/hero_shape_background.png' : '../../../assets/images/background_professionals.png'" alt="" class="background">
    <img src="../../../assets/images/hero_shape_circle.png" alt="" class="circle">
    <img [src]="icon" alt="" class="right">
    <img src="../../../assets/images/hero_shape_left.png" alt="" class="left" *ngIf="isPatientSection">
    <div class="portrait" *ngFor="let image of listOfPortraits, let i = index">
      <img [src]="image" alt="" [ngClass]="i === (invisibleImgIndex$ | async) ? '' : 'invisible_portrait'">
    </div>
  </div>
  <div class="more_mobile">
    <span class="text_mobile" [ngClass]="isPatientSection ? 'mobile_color_patients': 'mobile_color_pros'" (click)="navigateFn()">{{anchorToSectionForDoctors}}</span>
    <img [src]="isPatientSection ? '../../../assets/icons/arrow_toright.svg' : '../../../assets/icons/arrow_toright_white.svg'" alt="" class="icon_mobile">
  </div>
</div>
