import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-increase',
  templateUrl: './increase.component.html',
  styleUrls: ['./increase.component.scss']
})
export class IncreaseComponent implements OnInit{

  @Input() content: {
    title: string,
    image1: {fields: {file: {url: string}}},
    subtitle1: string,
    text1: string,
    image2: {fields: {file: {url: string}}},
    subtitle2: string,
    text2: string
  } = {
    title: '',
    image1: {fields: {file: {url: ''}}},
    subtitle1: '',
    text1: '',
    image2: {fields: {file: {url: ''}}},
    subtitle2: '',
    text2: ''
  }

  title = '';
  image1 = '';
  subtitle1 = '';
  text1 = '';
  image2 = '';
  subtitle2 = '';
  text2 = '';

  constructor(){}

  ngOnInit(): void {
    this.title = this.content.title;
    this.image1 = this.content.image1.fields.file.url;
    this.subtitle1 = this.content.subtitle1;
    this.text1 = this.content.text1;
    this.image2 = this.content.image2.fields.file.url;
    this.subtitle2 = this.content.subtitle2;
    this.text2 = this.content.text2;
  }
}
