import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MailchimpService } from 'src/app/services/mailchimp.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Output() toOpenContact = new EventEmitter();
  @Output() toOpenMailchimp = new EventEmitter();
  @Input() content: {
    title: string;
    subtitle: string;
  } = {
    title: '',
    subtitle: '',
  };

  title = '';
  subtitle = '';
  input = new FormControl('')

  constructor(private mailChimpService: MailchimpService) {
  }

  ngOnInit(): void {
    this.title = this.content.title;
    this.subtitle = this.content.subtitle;
  }

  addEmail(email: string | null) {

      this.mailChimpService.addEmail(email || '');
      this.toOpenMailchimp.emit();


  }

  openPop() {

    this.toOpenContact.emit();


}

}
