<app-header (navigate)="navigateToDoctors()" [content]="content['Header Patients']"></app-header>
<app-hero (toOpen)="openPop()" (navigate)="navigateToDoctors()" [content]="content['Hero Patients']"></app-hero>
<app-medical-network [content]="content['Medical Network']"></app-medical-network>
<app-video [content]="content['Video Patients']" (toOpen)="openPop()"></app-video>
<app-centers (toOpen)="openPop()" [content]="content['Centers']"></app-centers>
<app-your-needs [content]="content['Your Needs']"></app-your-needs>
<app-steps [content]="content['Steps']"></app-steps>
<app-anchor (toOpen)="openPop()" [content]="content['Anchors']"></app-anchor>
<app-for-doctors [content]="content['Navigation Patients']"></app-for-doctors>
<app-footer (toOpenContact)="openPopContact()" (toOpenMailchimp)="openPopMailchimp()" [content]="content['Footer Patients']"></app-footer>
<app-pop-up-patients *ngIf="popIsOpen" (toClose)="closePop()"></app-pop-up-patients>
<app-pop-up-contact *ngIf="popContactIsOpen" (toCloseContact)="closePopContact()"></app-pop-up-contact>
