import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {
  patients: any[] = [];
  clinics: any[] = [];
  doctors: any[] = [];
  services: any[] = [];

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  async getPatients() {
    if (isPlatformServer(this.platformId)) {
      let result: any[];
      result = await firstValueFrom(
        this.http.get<any[]>(environment.content.apiBaseRoute + '/patients')
      );
      this.patients = result.map((section) => section.fields);
      return;
    } else {
      let result: any[];
      result = await firstValueFrom(
        this.http.get<any[]>(environment.content.apiBaseRoute + '/patients')
      );
      this.patients = result.map((section) => section.fields);
      return;
    }
  }

  async getClinics() {
    let result: any[];
    result = await firstValueFrom(
      this.http.get<any[]>(environment.content.apiBaseRoute + '/clinics')
    );
    this.clinics = result.map((section) => section.fields);
    return;
  }

  async getDoctors() {
    let result: any[];
    result = await firstValueFrom(
      this.http.get<any[]>(environment.content.apiBaseRoute + '/doctors')
    );
    this.doctors = result.map((section) => section.fields);
    return;
  }

  async getServices() {
    let result: any[];
    result = await firstValueFrom(
      this.http.get<any[]>(environment.content.apiBaseRoute + '/services')
    );
    this.services = result.map((section) => section.fields);
    return;
  }
}
