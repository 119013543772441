import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Email } from '../interfaces/Email';

@Injectable({
  providedIn: 'root',
})
export class NodemailerService {
  apiBaseRoute = environment.email.apiBaseRoute;
  sendRoute = this.apiBaseRoute + '/send/';

  constructor(private http: HttpClient) {}

  sendEmail(body: Email | any) {
    return this.http.post(this.sendRoute, body);
  }
}
