<section class="anchors">
  <article class="box downloads">
    <h3>{{titleLeft}}</h3>
    <span>{{textLeft}}</span>
    <div class="to_stores">
      <a href="https://www.apple.com/es/app-store/" target="_blank" class="apple">
        <img src="../../../assets/images/button_ios.svg" alt="" >
      </a>

      <a href="https://play.google.com/store/apps" target="_blank" class="google">
        <img src="../../../assets/images/button_go.svg" alt="" >
      </a>
    </div>
  </article>
  <article class="box more_info">
    <h3>{{titleRight}}</h3>
    <span>{{textRight}}</span>
    <button (click)="openPop()">{{textButton}}</button>
  </article>
</section>
