import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { FooterComponent } from "./components/footer/footer.component";
import { RouterModule } from "@angular/router";
import { HeaderComponent } from "./components/header/header.component";
import { HeroComponent } from "./components/hero/hero.component";
import { VideoComponent } from "./components/video/video.component";
import { ForDoctorsComponent } from "./components/for-doctors/for-doctors.component";
import { JoinUsComponent } from './components/join-us/join-us.component';
import { PopUpContactComponent } from './components/pop-up-contact/pop-up-contact.component';
import { TrustpilotBoxComponent } from './components/trustpilot-box/trustpilot-box.component';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    HeroComponent,
    VideoComponent,
    ForDoctorsComponent,
    JoinUsComponent,
    PopUpContactComponent,
    TrustpilotBoxComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverAPp'}),
    CommonModule,
    ReactiveFormsModule,
    RouterModule
  ],
  providers: [],
  exports: [
    FooterComponent,
    HeaderComponent,
    HeroComponent,
    VideoComponent,
    ForDoctorsComponent,
    JoinUsComponent,
    PopUpContactComponent,
    TrustpilotBoxComponent,
  ]
})
export class SharedModule {

}
