import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { getPatients } from './guards/getPatients.guard';
import { PatientsComponent } from './patients/patients.component';
import { ClinicsComponent } from './clinics/clinics.component';
import { DoctorsComponent } from './doctors/doctors.component';
import { getClinics } from './guards/getClinics.guard';
import { getDoctors } from './guards/getDoctors.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'pacientes',
    pathMatch: 'full'
  },
  {
    path: 'pacientes',
    canActivate: [getPatients],
    component: PatientsComponent
  },
  {
    path: 'clinicas',
    canActivate: [getClinics],
    component: ClinicsComponent
  },
  {
    path: 'medicos',
    canActivate: [getDoctors],
    component: DoctorsComponent
  }
]


@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
