import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-join-us',
  templateUrl: './join-us.component.html',
  styleUrls: ['./join-us.component.scss']
})
export class JoinUsComponent implements OnInit{

  @Output() toOpen = new EventEmitter();

  @Input() content : {
    title: string,
    button: string
  } = {title: '', button: ''}

  title = '';
  button = ''

  ngOnInit(): void {
      this.title = this.content.title;
      this.button = this.content.button;
  }

  openPop() {

    this.toOpen.emit();


}
}
